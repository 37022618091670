// UpDownAnimation.js

import React from 'react';


const Rightupdown = () => {
  return (
    <div className="image-container">
    
      <img src="./MixCollage-26-Jan-2024-08-58-PM-5239-removebg-preview.png" alt="Your Image" className="image-containerR" />

      
    </div>
  );
};

export default Rightupdown;

// UpDownAnimation.js

import React from 'react';


const UpDownAnimation = () => {
  return (
    <div className="image-container">
      <img src="./Image_R.png" alt="Your Image" className="up-down-image" />
    

      
    </div>
  );
};

export default UpDownAnimation;
